<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i>  แก้ไขประเภทปัญหา</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
	          <form-issue-type  :item="item" />
	          <div class="float-right d-flex">
	            <span><router-link :to="'/issue-type'" class="nav-link text-underline"> กลับ</router-link></span>
	            <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
	            <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
	          </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formIssueType from './form.issue_type';
export default {
  name: 'issue_type-view-page-issue_type-edit',
  data () {
    return {
      permission:{
        appSlug: 'issue-type',
        actionData: {}
      },
      item: {},
      id: ''
    }
  },
  methods: {
    async editHandler () {
      const result = await this.HttpServices.putData(`/ab_issue_type/${this.item.id}`, this.item);

      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/issue-type');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/issue-type");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_issue_type/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลประเภทปัญหา`, ()=>{
          this.$router.push('/issue-type');
        });
      }
    },
  },
  components: {
    formIssueType
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-issue-type");
    await this.getItem();
  }
}
</script>
